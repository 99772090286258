import React from "react";
import BaseLayout from "../components/baseLayout";
import HeroTitle from "../components/heroTitle";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { TagTool } from "../components/smallsComponents";
import "../style/processesAndTools.css";
import Link from "gatsby-link";

export default function ProcessesAndTools() {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//content/processesAndTools//" } }
        sort: { order: ASC, fields: [frontmatter___order] }
      ) {
        edges {
          node {
            frontmatter {
              name
              path
              description
              type
              href
              img {
                childImageSharp {
                  gatsbyImageData(width: 350, height: 350)
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <BaseLayout>
      <HeroTitle title="Processes and Tools" />
      <div className="section container">
        <div className="columns is-multiline is-centered is-mobile">
          {data.allMarkdownRemark.edges.map(({ node }, index) => (
            <Tool node={node} key={index} />
          ))}
        </div>
      </div>
    </BaseLayout>
  );
}

function Tool({ node }) {
  const path = node.frontmatter.path;
  const name = node.frontmatter.name;
  const type = node.frontmatter.type;
  const href = node.frontmatter.href;
  const description = node.frontmatter.description;
  const gatsbyImageData = node.frontmatter.img.childImageSharp.gatsbyImageData;
  return (
    <Link
      className="shadow round responsiveMargin fixedWidth has-text-centered"
      to={href ?? path}
    >
      <GatsbyImage image={gatsbyImageData} alt={name} />
      <div className="margin tool-description">
        <div className="has-text-weight-bold is-size-5">{name}</div>
        <div>{description}</div>
        <TagTool type={type} />
      </div>
    </Link>
  );
}
